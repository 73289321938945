<template>
  <v-container class="">

    <v-row no-gutters class="">

      <v-col :cols="portrait ? 12 : 7" class="mt-n2" >
        <div class="chartAndText">
          gurka
          <!-- <DonutChart :chartdata="chartdata" /> -->
          <DonutChart :class="{chartSizePortrait: portrait, chartSizeLandscape: !portrait}" :chartdata="chartdata" />
          <div class="onTop mt-1">
            <p><span class="font-weight-bold" :class="{dynamicTextPortrait: portrait, dynamicTextLandscape: !portrait}" >{{ $store.getters.getCowQCounts.total }}</span><br /><span :class="{dynamicMediumTextPortrait: portrait, dynamicMediumTextLandscape: !portrait}">{{$t("All Cows")}}</span></p>
          </div>
        </div>
      </v-col>

      <v-col :cols="portrait ? 12 : 5" :class="{'mt-1': portrait, 'mt-5': !portrait}" >
        <v-row no-gutters>
          <v-col cols="5" align="center" class="mb-2 mr-1 ml-1" :style="{'white-space': 'nowrap'}">
              <CowHeadIcon :fill="theme.MilkOverdue" class="cowHeadSizer mr-1 mb-n2" /><span :class="{dynamicMediumTextPortrait: portrait, dynamicMediumTextLandscape: !portrait}">{{ $store.getters.getCowQCounts.red }}</span>
          </v-col>
          <v-col cols="5" align="center" class="mb-2 mr-1  ml-1" :style="{'white-space': 'nowrap'}">
              <CowHeadIcon :fill="theme.yellow" class="cowHeadSizer mr-1 mb-n2" /><span :class="{dynamicMediumTextPortrait: portrait, dynamicMediumTextLandscape: !portrait}">{{ $store.getters.getCowQCounts.yellow }}</span>
          </v-col>
          <v-col cols="5" align="center" class="mb-2 mr-1  ml-1" :style="{'white-space': 'nowrap'}">
              <CowHeadIcon :fill="theme.white" class="cowHeadSizer mr-1  mb-n2" /><span :class="{dynamicMediumTextPortrait: portrait, dynamicMediumTextLandscape: !portrait}">{{ $store.getters.getCowQCounts.white }}</span>
          </v-col>
          <v-col cols="5" align="center" class="mb-2 mr-1  ml-1" :style="{'white-space': 'nowrap'}">
              <CowHeadIcon :fill="theme.FeedOnly" class="cowHeadSizer mr-1 mb-n2" /><span :class="{dynamicMediumTextPortrait: portrait, dynamicMediumTextLandscape: !portrait}">{{ $store.getters.getCowQCounts.green }}</span>
          </v-col>
          <v-col cols="5" align="center" class="mb-2 mr-1  ml-1" :style="{'white-space': 'nowrap'}">
              <img :src="require('@/assets/incomplete4.png')" class="cowHeadSizer mr-1 mb-n2" /><span :class="{dynamicMediumTextPortrait: portrait, dynamicMediumTextLandscape: !portrait}">{{ $store.getters.getCowQCounts.incomplete }}</span>
          </v-col>
        </v-row>
      </v-col>
        
    </v-row>

  </v-container>
</template>

<script>
import DonutChart from "@/components/DonutChart.vue";
import CowHeadIcon from "@/assets/cowhead.svg";

export default {
  name: "CowQOverview",

  components: {
    DonutChart,
    CowHeadIcon,
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      portrait: this.$store.state.isPortraitMode,
    };
  },
  watch: {
    "$store.state.isPortraitMode": function (currentValue, previousValue) {
      this.portrait = currentValue;
    },
  },
  computed: {
    chartdata: {
      get() {
        let { green, white, yellow, red } = this.$store.getters.getCowQCounts;
        let colorArray = [this.theme.MilkOverdue, 
                          this.theme.yellow, 
                          this.theme.white,
                          this.theme.FeedOnly];
        let colorBorderArray =  colorArray;
        return {
          datasets: [
            {
              label: "CowQ Donut",
              data: [red, yellow, white, green],
              backgroundColor: colorArray,
              hoverBackgroundColor: colorArray,
              borderColor: colorBorderArray,
              hoverBorderColor: colorArray,
              hoverOffset: 4,
            },
          ],
        };
      },
    },
  },
};
</script>
<style scoped>
.chartAndText {
  position: relative;
  text-align: center;
  line-height: 1.1;
}
.onTop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.chartSizePortrait {
  height: calc(50vh - 190px)
}
.chartSizeLandscape {
  height: calc(50vh - 174px)
}
.cowHeadSizer {
  height: clamp(30px, 5vw, 35px);
  width: clamp(30px, 5vw, 35px);
}
</style>